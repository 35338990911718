import './styles/main.scss';
import 'simplebar/dist/simplebar.min.css';
import { Outlet } from "react-router-dom";
import Navbar from "./components/navbar";
import React, { RefObject } from "react";
import SimpleBar from 'simplebar-react';
import PullToRefresh from 'react-simple-pull-to-refresh';

export default class App extends React.Component {
  simpleBarRef: RefObject<SimpleBar>;

  constructor(props: any) {
    super(props);
    this.simpleBarRef = React.createRef();
    window.addEventListener("componentMountComplete", async () => {
      setTimeout(() => {
        if (this.simpleBarRef.current?.getScrollElement().scrollTop) this.simpleBarRef.current.getScrollElement().scrollTop = 0;
        if (this.simpleBarRef.current?.recalculate) this.simpleBarRef.current?.recalculate();
      }, 100);
    });
  }

  render() {
    return (
      <div className="home">
        <Navbar />
        <PullToRefresh onRefresh={async () => window.location.reload()}>
          <SimpleBar ref={this.simpleBarRef} className="main-content-height" forceVisible="y" autoHide={false}>
            <Outlet />
          </SimpleBar>
        </PullToRefresh>
      </div>
    );
  }
}
