import React from "react";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import ReactDOM from "react-dom";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import $ from "jquery";
import Popper from 'popper.js';
import 'mdbreact/dist/css/mdb.css';
import App from "./App";
import Home from "./routes/home";
import About from "./routes/about";
import Contact from "./routes/contact";
import Redirect from "./routes/redirect";
import TsasWebsite from "./routes/projects/tsas-website";
import ArBuilder from "./routes/projects/ar-builder";
import ArjsUnityPlugin from "./routes/projects/arjs-unity-plugin";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="" element={<Redirect />} />
          <Route path="home" element={<Home />} />
          <Route path="about" element={<About />} />
            <Route path="projects/tsas-website" element={<TsasWebsite />} />
            <Route path="projects/ar-builder" element={<ArBuilder />} />
            <Route path="projects/arjs-unity-plugin" element={<ArjsUnityPlugin />} />
          <Route path="contact" element={<Contact />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
