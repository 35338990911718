
import { useNavigate } from "react-router-dom";
import React from "react";

export default function Redirect() {
    const navigate = useNavigate();

    React.useEffect(() => {
        navigate("/home");
    });

    return <div></div>;
}