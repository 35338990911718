import { EmailJSResponseStatus } from "emailjs-com";
import * as EmailJs from "emailjs-com";
import BannerImg from "../assets/imgs/email-image-bg.jpg";
import React from "react";
import TsasFooter from "../components/tsas-footer";

interface IContactProps {
}

interface IContactState {
    fromName: string;
    fromEmail: string;
    message: string;
    emailSent: boolean;
    isProcessing: boolean;
    failValidationString: string;
}

export default class Contact extends React.Component<IContactProps, IContactState> {

    private constructor(props: any) {
        super(props);
        this.state = {
            fromName: "",
            fromEmail: "",
            message: "",
            emailSent: false,
            isProcessing: false,
            failValidationString: "",
        };
        EmailJs.init("user_YpuqgIxFkgZrjrT2F3NYd");
    }

    componentDidMount() {
        window.dispatchEvent(new Event("componentMountComplete"));
    }

    render() {
        return (
            <div className="d-flex flex-column main-content-height">
                <div className="banner-img-container">
                    <img src={BannerImg} className="banner-img" alt="Responsive image" />
                </div>


                <div className="container px-1 pt-3 px-md-5">
                    <div className='row'>
                        <div className='col'>
                            <div className="alert alert-danger mt-2" role="alert" style={{ display: `${!this.state.failValidationString ? "none" : ""}` }}>
                                {this.state.failValidationString}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container px-1 py-3 px-md-5" style={{ display: `${!this.state.emailSent ? "none" : ""}` }}>
                    <div className="row">
                        <div className="col">

                            <div className="card bg-dark">
                                <div className="card-body">
                                    <h1 className="card-title">Thanks For Contacting Me! I&apos;ll Get Back With You As Soon As Possible.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container px-1 py-3 px-md-5" style={{ display: `${this.state.emailSent ? "none" : ""}` }}>
                    <div className="row">
                        <div className="col">

                            <div className="card bg-dark">
                                <div className="card-body">
                                    <h1 className="card-title">For All Inquiries, Please Email Me</h1>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="exampleFormControlInput1">Name</label>
                                                    <input type="name" className="form-control" id="exampleFormControlInput1" placeholder="Name" onChange={this.changeName.bind(this)} value={this.state.fromName} />
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="exampleFormControlInput2">Email</label>
                                                    <input type="email" className="form-control" id="exampleFormControlInput2" placeholder="name@example.com" onChange={this.changeEmail.bind(this)} value={this.state.fromEmail} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label htmlFor="message">Message</label>
                                                    <textarea className="form-control" id="message" onChange={this.changeMessage.bind(this)} value={this.state.message}></textarea>
                                                </div>

                                                <button type="button" className="btn" disabled={this.state.isProcessing} onClick={this.sendEmail.bind(this)}>Send</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >

                <div className="mt-auto">
                    <TsasFooter />
                </div>
            </div >
        );
    }

    private changeName(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ fromName: e.target.value });
    }

    private changeEmail(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ fromEmail: e.target.value });
    }

    private changeMessage(e: any) {
        this.setState({ message: e.target.value });
    }

    private async sendEmail() {
        if (!this.validate()) return;

        this.setState({ isProcessing: true });

        const templateParams = {
            fromName: this.state.fromName,
            fromEmail: this.state.fromEmail,
            message: this.state.message,
            toEmail: "techstuffandsuch@gmail.com",
            toName: "TechStuff",
        }

        const response: EmailJSResponseStatus = await EmailJs.send("service_vqe7s1l", "template_psgqrtl", templateParams);
        if (response.status === 200) {
            this.setState({ emailSent: true, isProcessing: false });
        }
    }

    private validate(): boolean {
        this.setState({ failValidationString: "" });

        if (!this.state.fromName || !this.state.fromEmail || !this.state.message) {
            this.setState({ failValidationString: "Please fill out all forms." });
            return false;
        }

        return true;

    }
}