import BGImg from "../assets/imgs/light-bulb.jpg";
import React from "react";
import TsasFooter from "../components/tsas-footer";

export default class Home extends React.Component {

  componentDidMount() {
    window.dispatchEvent(new Event("componentMountComplete"));
  }
  
  render() {
    return (
      <div style={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        backgroundImage: `url(${BGImg})`
      }}>
        <h1 title="Spider-Man (2002) - I'm Something of a Scientist Myself" className="text-center pt-5"
          onClick={() => window.open('https://www.youtube.com/watch?v=D_oTxsHK5d8')}>
          I&apos;m something of a scientist,<br />myself</h1>
        <div style={{ height: "30em" }}></div>
        <div className="container-fluid quote-background">
          <div className="row" style={{ height: "20em" }}>
            <div className="col text-center my-auto">
              <div className="blockquote">
                <p title="Shia Labeouf 'Just Do It' Motivational Speech" className="playfair-display" onClick={() => window.open('https://www.youtube.com/watch?v=ZXsQAXx_ao0')}>
                  Don&apos;t let your dreams be dreams<br />
                  Yesterday you said tomorrow<br />
                  So just do it<br />
                  Make your dreams come true<br />
                  Just do it<br />
                </p>
                <footer title="'Shia Labeouf' Live - Rob Cantor" className="blockquote-footer text-muted" onClick={() => window.open('https://www.youtube.com/watch?v=o0u4M6vppCI')}>
                  Hollywood Superstar Shia Labeouf
                </footer>
              </div>
            </div>
          </div>
        </div>
        <TsasFooter />

      </div>
    );
  }
}