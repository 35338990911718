import CSharpIcon from "../../assets/imgs/styled-words-icons/c-sharp-icon.png";
import QRCode from "../../assets/imgs/QR-code.png";
import AFrameIcon from "../../assets/imgs/styled-words-icons/a-frame-icon.jpg";
import React from "react";
import StyledWord from '../../components/styled-word';
import TsasFooter from "../../components/tsas-footer";
import UnityIcon from "../../assets/imgs/styled-words-icons/unity-icon.png";
import UnityLogoPattern from "../../assets/imgs/pattern-UnityLogoARjs.png";
import ThreeJsIcon from "../../assets/imgs/styled-words-icons/three-js-icon.png";

export default class ArjsUnityPlugin extends React.Component {
    componentDidMount() {
        window.dispatchEvent(new Event("componentMountComplete"));
    }

    render() {
        return (
            <div className="d-flex flex-column main-content-height">
                <div className="container px-1 py-3 px-md-5">
                    <div className="row">
                        <div className="col">
                            <div className="card bg-dark">
                                <div className="card-body">
                                    <div className='container card-title p-0'>
                                        <div className='row'>
                                            <div className='col-auto' style={{ display: "flex" }}>
                                                <h1 className="d-inline-block">AR.js_Unity Plugin (<code>Deprecated</code>)</h1>
                                            </div>
                                            <div className='col-auto my-auto'>
                                                <StyledWord className="mr-2" iconImageSrc={UnityIcon} word="Unity 3D" />
                                                <StyledWord className="mr-2" iconImageSrc={CSharpIcon} word="C#" />
                                                <StyledWord className="mr-2" iconImageSrc={AFrameIcon} word="A-Frame" />
                                                <StyledWord className="mr-2" iconImageSrc={ThreeJsIcon} word="Three.js" />
                                            </div>
                                        </div>
                                    </div>
                                    <p className="light-text m-2">Bringing AR.js to everyone&apos;s favorite 3D Augmented Reality development environment. AR.js is a framework that enables Augmented Reality for the web, allowing
                                        people to create AR projects that don&apos;t need a standalone application for iOS or Android to experience. AR.js could use a 3D development environment to make
                                        object placement easier though, which is what this plugin is all about. Check it out on <a href="https://github.com/weldonla/ARjs_Unity"
                                            target="_blank" rel="noopener noreferrer">GitHub</a>.
                                    </p>
                                    <div className="container p-0">
                                        <div className="row">
                                            <div className="col">
                                                <h2>Demonstration</h2>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12 col-lg-6 p-3 px-4 px-lg-3 py-lg-1 text-center">
                                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/PYs_Y1U2_DI" title="YouTube video player" frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                            </div>
                                            <div className="col-12 col-lg-6 p-3 px-4 px-lg-3 py-lg-1 text-center">
                                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/_WnyC2ZrDls" title="YouTube video player" frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col">
                                                <h2>Results</h2>
                                            </div>
                                        </div>
                                        <div className="row m-2">
                                            <div className="col-12 col-lg-6 p-3 px-lg-3 py-lg-1 text-center">
                                                <div className='p-4' style={{ backgroundColor: "white" }}>
                                                    <img className="img w-100" style={{ maxWidth: "450px" }} src={UnityLogoPattern} />
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6 p-3 px-lg-3 py-lg-1 text-center my-auto">
                                                <img className="img-thumbnail w-100" style={{ maxWidth: "170px" }} src={QRCode} />
                                                <h3 className="mt-3">Scan The Qr Code</h3>
                                                <p>
                                                    Scan the QR Code with your phone, then point your phone at the image to the left to take a look at the Augmented Reality experience that was built using this
                                                    plugin. It will work best if you print off the image onto a piece of paper and lay it on a flat surface.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-auto">
                    <TsasFooter />
                </div>
            </div>
        );
    }
}