import React from "react";
import logo from '../assets/imgs/logo.svg';

export default function TsasFooter() {
    const currentYear = new Date().getFullYear()
    return (
        <footer>
            <div className="container-fluid footer-height py-4 bg-dark w-100 m-0">
                <div className="row">
                    <div className="col text-center">
                        <a className="h6" href="https://www.youtube.com/channel/UCbXr5Gw-Xz4RDtGdf2ZXjgg" target="_blank" rel="noopener noreferrer">YouTube Channel</a>
                        <h6 className="mt-3">Copyright © {currentYear} TechStuff AndSuch</h6>
                        <h6>powered by <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">React</a> <img src={logo} className="App-logo" alt="logo" /></h6>
                    </div>
                </div>
            </div>
        </footer>
    );
}