import React from "react";
import AFrameIcon from "../../assets/imgs/styled-words-icons/a-frame-icon.jpg";
import ARBuilderScreenShot from "../../assets/imgs/ar-builder-ss-1.png";
import StyledWord from '../../components/styled-word';
import TsasFooter from "../../components/tsas-footer";
import ThreeJsIcon from "../../assets/imgs/styled-words-icons/three-js-icon.png";
import FullScreenButton from "../../assets/imgs/ar-builder/fullscreen.png";
import ExitFullScreenButton from "../../assets/imgs/ar-builder/exit_fullscreen.png";

export default class ArBuilder extends React.Component {
    componentDidMount() {
        window.dispatchEvent(new Event("componentMountComplete"));
    }

    render() {
        return (
            <div className="d-flex flex-column main-content-height">
                <div className="container px-1 py-3 px-md-5">
                    <div className="row">
                        <div className="col">
                            <div className="card bg-dark">
                                <div className="card-body">
                                    <div className='container card-title p-0'>
                                        <div className='row'>
                                            <div className='col-auto' style={{ display: "flex" }}>
                                                <h1 className="d-inline-block">AR Builder</h1>
                                            </div>
                                            <div className='col-auto my-auto'>
                                                <StyledWord className="mr-2" iconClass="fab fa-react" iconColor="#61DBFB" word="React" />
                                                <StyledWord className="mr-2" iconImageSrc={AFrameIcon} word="A-Frame" />
                                                <StyledWord className="mr-2" iconImageSrc={ThreeJsIcon} word="Three.js" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container p-0">
                                        <div className="row mt-2">
                                            <div className="col-12 col-lg-6 p-3 px-4 px-lg-3 py-lg-1 text-left">
                                                <p className="light-text">
                                                    So my old AR builder was a Unity plugin. This meant I had to do a lot of work arounds to turn it into html.
                                                    It was a nightmare to work with since everything I wanted to do (like animation with keyframes) I had to essentially code twice.
                                                    Everything needed done in Unity, and then I needed to change that to html. So I&apos;m hereby
                                                    deprecating it to start another project that I have some high hopes for. Click <a href={window.location.origin + "/ar-builder.html"}
                                                        target="_blank" rel="noopener noreferrer">here</a> to
                                                    check it out. If you do take a look, just know that you have to click the &quot;back to scene&quot; button in the upper left to
                                                    test it with your marker image. I&apos;ll have more videos about it sometime in the future. This project was forked from
                                                    the <a href="https://github.com/aframevr/aframe-inspector" target="_blank" rel="noopener noreferrer">Aframe Inspector</a> GitHub repo.
                                                    The inspector itself is meant to be an inspector (not an editor), so I&apos;ll be fixing it up over time to add functionality and
                                                    whatnot and to integrate it with other kinds of AR or VR (maybe, who knows). Look up and print off &quot;Hiro Marker&quot;
                                                    from google images to tinker around with the builder.
                                                </p>
                                            </div>
                                            <div className="col-12 col-lg-6 p-3 px-4 px-lg-3 py-lg-1 text-center">
                                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/F8zP6w062I4" title="YouTube video player" frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-12 col-lg-6'>
                                                <h2 className='mt-2'>Updates</h2>
                                                <ul>
                                                    <li>
                                                        <p className="light-text m-2">
                                                            Adding a new entity will add it to the selected item, so make sure to have the marker object selected when clicking
                                                            the plus button.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p className="light-text m-2">
                                                            Clicking the save icon will download an index.html file that you can use as an ar demo. Make sure to add these two
                                                            images to whatever folder you&apos;re going to put the index.html file in. <a href={FullScreenButton} 
                                                            download="fullscreen.png">fullscreen.png</a> and <a href={ExitFullScreenButton} 
                                                            download="exit_fullscreen.png">exit_fullscreen.png</a>
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='col-12 col-lg-6'>
                                                <h2 className='mt-2'>Notes</h2>
                                                <ul>
                                                    <li>
                                                        <p className="light-text m-2">
                                                            To actually see the objects, hit the eye icon on the marker object to show the marker and all it&apos;s children.
                                                            Before you save the index.html file, be sure to hit the eye to hide the marker and it&apos;s children again.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p className="light-text m-2">
                                                            If you&apos;re using this thing and want a particular feature added, feel free to contact me.
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col'>
                                                <img className='w-100 m-2' src={ARBuilderScreenShot} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-auto">
                    <TsasFooter />
                </div>
            </div>
        );
    }
}