import React from "react";

interface IStyledWordProps {
    className?: string,
    iconColor?: string,
    iconClass?: string,
    iconImageSrc?: string,
    word: string
}

export default function StyledWord(props: IStyledWordProps) {
    return (
        <div className={"styled-word my-1 py-1 px-2 " + props.className}>
            <i className={props.iconClass + " mr-1 my-auto"} style={{ display: `${props.iconClass ? "inline-block" : "none"}`, color: props.iconColor }} />
            <img className="my-auto mr-1" src={props.iconImageSrc} style={{ display: `${props.iconImageSrc ? "inline-block" : "none"}` }} />
            <h6 className="my-auto">{props.word}</h6>
        </div>
    );
}
