import React, { useState } from 'react';
import {
  MDBContainer, MDBNavbar, MDBNavbarBrand, MDBNavbarToggler, MDBIcon,
  MDBNavbarNav, MDBNavbarItem, MDBNavbarLink, MDBCollapse, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem
} from 'mdb-react-ui-kit';
import { useNavigate, useLocation } from "react-router-dom";

export default function Navbar() {
  const [showBasic, setShowBasic] = useState(false);
  const navigate = useNavigate();
  const routeName = useLocation().pathname.replace('/', '');

  return (
    <div className="container-fluid navbar-spacer">
      <div className="row">
        <div className="col">
          <MDBNavbar expand='md' dark bgColor='dark'>
            <MDBContainer fluid>
              <MDBNavbarBrand onClick={() => { navigate("/home"); setShowBasic(false); }}>TSAS</MDBNavbarBrand>

              <MDBNavbarToggler
                aria-controls='navbarSupportedContent'
                aria-expanded='false'
                aria-label='Toggle navigation'
                onClick={() => setShowBasic(!showBasic)}
              >
                <MDBIcon icon='bars' fas />
              </MDBNavbarToggler>

              <MDBCollapse navbar show={showBasic}>
                <MDBNavbarNav className='mr-auto'>

                  <MDBNavbarItem className={routeName === "home" ? "active" : ""}>
                    <MDBNavbarLink onClick={() => { navigate("/home"); setShowBasic(false); }}>Home</MDBNavbarLink>
                  </MDBNavbarItem>

                  {/* <MDBNavbarItem className={routeName === "projects" ? "active" : ""}>
                    <MDBNavbarLink onClick={() => { navigate("/projects"); setShowBasic(false); }}>Projects</MDBNavbarLink>
                  </MDBNavbarItem> */}

                  {/* <MDBNavbarItem className={routeName.includes("projects") ? "active" : ""}>
                    <MDBDropdown>
                      <MDBDropdownToggle $nav caret color="dark">
                        <span className="mr-2">Projects</span>
                      </MDBDropdownToggle>
                      <MDBDropdownMenu>
                        <MDBDropdownItem className={routeName === "projects/tsas-website" ? "active" : ""} onClick={() => { navigate("/projects/tsas-website"); setShowBasic(false); }}>This Website</MDBDropdownItem>
                        <MDBDropdownItem className={routeName === "projects/ar-builder" ? "active" : ""} onClick={() => { navigate("/projects/ar-builder"); setShowBasic(false); }}>AR Builder</MDBDropdownItem>
                        <MDBDropdownItem className={routeName === "projects/arjs-unity-plugin" ? "active" : ""} onClick={() => { navigate("/projects/arjs-unity-plugin"); setShowBasic(false); }}>AR.js_Unity Plugin (Depricated)</MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  </MDBNavbarItem> */}

                  <div className={"dropdown nav-item " + (routeName.includes("projects") ? "active" : "")}>
                    <a className="dropdown-toggle nav-link" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                      Projects
                    </a>

                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <li><a className={"dropdown-item " + (routeName.includes("tsas-website") ? "active" : "")} onClick={() => { navigate("/projects/tsas-website"); setShowBasic(false); }}>This Website</a></li>
                      <li><a className={"dropdown-item " + (routeName.includes("ar-builder") ? "active" : "")} onClick={() => { navigate("/projects/ar-builder"); setShowBasic(false); }}>AR Builder</a></li>
                      <li><a className={"dropdown-item " + (routeName.includes("arjs-unity-plugin") ? "active" : "")} onClick={() => { navigate("/projects/arjs-unity-plugin"); setShowBasic(false); }}>AR.js_Unity Plugin (Depricated)</a></li>
                    </ul>
                  </div>

                  <MDBNavbarItem className={routeName === "contact" ? "active" : ""}>
                    <MDBNavbarLink onClick={() => { navigate("/contact"); setShowBasic(false); }}>Contact</MDBNavbarLink>
                  </MDBNavbarItem>

                </MDBNavbarNav>
              </MDBCollapse>
            </MDBContainer>
          </MDBNavbar>
        </div>
      </div>
    </div>
  );
}