import React from "react";

export default class About extends React.Component {

  componentDidMount() {
      window.dispatchEvent(new Event("componentMountComplete"));
  }
  
  render() {
    return (
      <h2>About</h2>
    );
  }
}