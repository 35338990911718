import BootstrapIcon from "../../assets/imgs/styled-words-icons/bootstrap-icon.png";
import FirebaseIcon from "../../assets/imgs/styled-words-icons/firebase-icon.png";
import FirebaseScreenShot from "../../assets/imgs/firebase-setup-instructions.png";
import React from "react";
import ScssIcon from "../../assets/imgs/styled-words-icons/scss-icon.png";
import StyledWord from '../../components/styled-word';
import TsasFooter from "../../components/tsas-footer";
import TSIcon from "../../assets/imgs/styled-words-icons/type-script-icon.png";

export default class TsasWebsite extends React.Component {

    componentDidMount() {
        window.dispatchEvent(new Event("componentMountComplete"));
    }

    render() {
        return (
            // <SimpleBar className="main-content-height" autoHide={false}>
            <div className="d-flex flex-column main-content-height">
                <div className="container px-1 py-3 px-md-5">
                    <div className="row">
                        <div className="col">
                            <div className="card bg-dark">
                                <div className="card-body">
                                    <div className='container card-title p-0'>
                                        <div className='row'>
                                            <div className='col-auto' style={{ display: "flex" }}>
                                                <h1 className="d-inline-block">This Website</h1>
                                            </div>
                                            <div className='col-auto my-auto'>
                                                <StyledWord className="mr-2" iconClass="fab fa-react" iconColor="#61DBFB" word="React" />
                                                <StyledWord className="mr-2" iconImageSrc={TSIcon} word="TypeScript" />
                                                <StyledWord className="mr-2" iconImageSrc={ScssIcon} word="SCSS" />
                                                <StyledWord className="mr-2" iconImageSrc={BootstrapIcon} word="Bootstrap" />
                                                <StyledWord className="mr-2" iconImageSrc={FirebaseIcon} word="Firebase" />
                                            </div>
                                        </div>
                                    </div>
                                    <p className='light-text m-2'>
                                        Over the past 2 and a half years, I&apos;ve gained a proficiency with <a href="https://aurelia.io/" target="_blank" rel="noopener noreferrer">Aurelia</a> and other
                                        various front end tools and languages. My old website was a very simple wordpress website and the contact me page kept breaking. I
                                        wanted to have better control of the entire website, and I now have the skills to do that. Plus, I thought I could incorporate another project into this
                                        webpage so here I am.
                                    </p>
                                    <h2 className='mt-2'>How?</h2>
                                    <p className="light-text m-2">
                                        I had originally remade this entire website using the Aurelia framework. After having 2+ years experience with it, I was able to replicate pretty much
                                        every aspect of my old wordpress website easily within a week, working on it in my free time. However, it seems like <a href="https://reactjs.org/" 
                                        target="_blank" rel="noopener noreferrer">React</a> is much more popular and more widely used. So I just remade it again using React. Also, I&apos;ve 
                                        become very fond of using TypeScript, Sass, and Bootstrap, so those are all here under the hood too. I had originally used Microsoft Azure for hosing 
                                        my site, since it&apos;s what I use for work. Setting it up in Azure took a few days and was probably the hardest part of remaking my website. 
                                        I eventually got the yaml file set up right, and it would automatically deploy whenever I would update the master branch on my GitHub repository. 
                                        That was all well and good until Microsoft charged me a little over $70 for my first month of service. That kind of bill is unacceptable for the 
                                        small personal scale that I&apos;m working with here.
                                    </p>
                                    <h2 className='mt-2'>Firebase to the Rescue</h2>
                                    <div className='container-fluid m-2 p-0'>
                                        <div className='row'>
                                            <div className='col-12 col-xl-4 text-left'>
                                                <p className='light-text'>
                                                    I cannot stress enough how easy this was in comparison. The screenshot here is all you really need to know. Aside from additionally
                                                    needing to use <code>firebase login</code> and <code>npm run build</code>, steps 1, 2, and 4 were basically all it took to have my
                                                    site deployed. It&apos;s also worth noting that Google&apos;s pricing is much more reasonable. So much so that I&apos;m probably
                                                    in their &quot;free tier&quot; and won&apos;t have to pay for anything other than the domain. But I&apos;ll know for sure after the
                                                    first month.
                                                </p>
                                                <p className='mt-1 light-text'>
                                                    It should maybe be noted the screenshot here was edited. I opened up the dev console in my web browser and edited it to just show
                                                    the commands that were used. These are still the actual steps though. If you&apos;re interested in seeing what the steps of setting
                                                    up web hosting with firebase are, you can check that out <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">here</a>.
                                                </p>
                                            </div>
                                            <div className='col-12 col-xl-8 mt-1 mt-xl-0 text-right'>
                                                <img className='w-100' src={FirebaseScreenShot} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-auto">
                    <TsasFooter />
                </div>
            </div>
        );
    }
}